<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Adesioni</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="event_container">
                    <div class="event_title">
                        {{ event.events_calendar_title }}
                    </div>

                    <div class="list">
                        <div v-if="adesioni.length == 0" class="no_partecipanti">
                            Nessun partecipante al momento
                        </div>
                        <div v-else>
                            <div class="title">Di seguito trovi l'elenco dei partecipanti</div>
                            <div v-for="(partecipante, index) in adesioni" :key="index" class="partecipante">
                                <div class="riferimento">{{ riferimentoPartecipante(partecipante) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline, arrowForwardCircle } from "ionicons/icons";

import moment from "moment";

import { dateFormat, dateFormatWithMinutes } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiEventi from "@/services/eventi";

export default defineComponent({
    name: "EventDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();
        const loggedUserId = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_user_id;
        const loggedDipendenteId = JSON.parse(localStorage.getItem("userPlanInfo")).dipendenti_id;

        //console.log(props.data);
        const event = ref([]);
        event.value = { ...props.data };
        console.log(event.value);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Format datetime to DD/MM/YY HH:mm
         */
        function dateTimeFormat(dateTime) {
            if (dateTime && moment.isDate(new Date(dateTime))) {
                return moment(dateTime).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        }

        function riferimentoPartecipante(partecipante) {
            if (partecipante.users_first_name && partecipante.users_last_name) {
                return `${partecipante.users_first_name} ${partecipante.users_last_name}`;
            } else if (partecipante.users_first_name && !partecipante.users_last_name) {
                return `${partecipante.users_first_name}`;
            }
            return `${partecipante.users_last_name}`;
        }

        /**
         * ! Load event adesioni
         */
        const loadingAdesioni = ref(false);
        const adesioni = ref([]);
        async function loadAdesioni() {
            loadingAdesioni.value = true;
            try {
                adesioni.value = await apiEventi.getAdesioni(event.value.events_calendar_id);
                console.log(adesioni.value);
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei partecipanti", "toast_danger");
            }
            loadingAdesioni.value = false;
        }
        loadAdesioni();

        return {
            dateFormat,
            dateFormatWithMinutes,
            arrowBackOutline,
            closeModal,
            arrowForwardCircle,
            event,
            adesioni,
            dateTimeFormat,
            riferimentoPartecipante,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background: var(--ion-color-primary, #3880ff) !important;
    padding: 8px;
    color: #ffffff;
}

ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}

/* lead v2*/
.event_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.event_container .event_title {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #475569;
    margin-bottom: 8px;
}
.event_container .city {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin-bottom: 8px;
}
.event_container .list {
    width: 100%;
}
.event_container .list .title {
    margin: 12px 0;
}
.event_container .partecipante {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.event_container .partecipante:last-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    border-bottom: none;
}
.partecipante .riferimento {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}

.no_partecipanti {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
    margin: 16px 0;
}
</style>
